import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./TheNameIsTheFameOfTheGame.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/fame/tile.png";
import InSitu1 from "./../../../../res/fame/insitu-1.jpeg";
import InSitu2 from "./../../../../res/fame/insitu-2.jpeg";
import InSitu3 from "./../../../../res/fame/insitu-3.jpeg";
import InSitu4 from "./../../../../res/fame/insitu-4.jpeg";

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu1, name: "InSitu1" },
    { src: InSitu2, name: "InSitu2" },
    { src: InSitu3, name: "InSitu3" },
  ],
};

const TheNameIsTheFameOfTheGame = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="Fame"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>THE FAME IS THE NAME OF THE GAME<h2 style={{paddingLeft: "16px"}}></h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="Fame"/>
        <br />
        <p>
      The Ghost Galerie is pleased to present its new exhibition: Then and Now, New York Street Style. A "different continuity" of its summer exhibition: New York Street Style, creation of the 80s. An exhibition that allows us to see, on the 3 floors of the gallery, about forty works from the 80's to nowadays, from about twenty artists. An artistic discovery mixing paintings, works on paper and sculptures, gathered around 3 styles: abstract art, figurative and lettering. Not to mention photography and installations! Were these works present during the first hanging ? Not sure. Are they still in conversation with their neighbors ? It's not easy to say. Were the creators of these neighbors already exhibited? To be seen. Will they remain throughout the exhibition? Nothing is less sure. To be discovered until November 15th at 62 rue du faubourg Saint-Honoré, Paris 8ème.
        
        {/* La programmation de GHOST GALERIE Marseille dévoile une nouvelle série d'expositions. Une sélection d’oeuvres d’artistes new-yorkais produites dans les années 80-90. Un accrochage en mouvement qui permet aux visiteurs de vivre une expérience irremplaçable, une rencontre sensible et personnelle avec cette création contemporaine, sortie tout droit de l'imaginaire de pré-ado & d'ado. L'exposition the name is the fame of the game nous permet de nous replonger dans les fondements du graffiti New Yorkais et de ses constructions mentales et artistiques une fois réinventé en atelier. Une découverte tout en style - lettrage, abstrait, figuratif - qui permet à chaque visiteur de découvrir un nom et derrière chaque nom un artiste et un style. */}
        </p>
        {/* The Ghost Galerie is pleased to present its new exhibition: Then and Now, New York Street Style. A "different continuity" of its summer exhibition: New York Street Style, creation of the 80s. An exhibition that allows us to see, on the 3 floors of the gallery, about forty works from the 80's to nowadays, from about twenty artists. An artistic discovery mixing paintings, works on paper and sculptures, gathered around 3 styles: abstract art, figurative and lettering. Not to mention photography and installations! Were these works present during the first hanging ? Not sure. Are they still in conversation with their neighbors ? It's not easy to say. Were the creators of these neighbors already exhibited? To be seen. Will they remain throughout the exhibition? Nothing is less sure. To be discovered until November 15th at 62 rue du faubourg Saint-Honoré, Paris 8ème.</p> */}
          {/* <a className={DownloaderWrapper} href={DazePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a> */}
        </div>
      </div>
      <br />
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default TheNameIsTheFameOfTheGame;

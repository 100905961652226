// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "TheNameIsTheFameOfTheGame-module--CarrouselWrapper2--nieUh";
export var DescriptionWrapper = "TheNameIsTheFameOfTheGame-module--DescriptionWrapper--lcfni";
export var DownloaderWrapper = "TheNameIsTheFameOfTheGame-module--DownloaderWrapper--IGHce";
export var EventWrapper = "TheNameIsTheFameOfTheGame-module--EventWrapper--Xysj2";
export var ImageWrapper = "TheNameIsTheFameOfTheGame-module--ImageWrapper--19cpv";
export var ImagesWrapper = "TheNameIsTheFameOfTheGame-module--ImagesWrapper--WxIiL";
export var MobileTile = "TheNameIsTheFameOfTheGame-module--MobileTile--QKRLJ";
export var PdpWrapper = "TheNameIsTheFameOfTheGame-module--PdpWrapper--rtEPf";
export var PhotosWrapper = "TheNameIsTheFameOfTheGame-module--PhotosWrapper--JkxD0";
export var TitleWrapper = "TheNameIsTheFameOfTheGame-module--TitleWrapper--XBKSX";
export var Wrapper = "TheNameIsTheFameOfTheGame-module--Wrapper--pWorT";